<template>
  <section>
    <loading-flux :value="loading"></loading-flux>
    <v-dialog
      v-model="modalAction"
      max-width="600px"
      @keydown="closeModalAddTracking"
      @click:outside="closeModalAddTracking"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6">Añadir nuevo registro de tracking</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form>
              <v-col cols="12" md="12" sm="12">
                <v-autocomplete
                  v-model="$v.form.project_code.$model"
                  :error="$v.form.project_code.$invalid && submitUpload"
                  :items="projects"
                  label="Centro de costo"
                  item-text="custom_code"
                  clearable
                  item-value="code"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-autocomplete
                  v-model="$v.form.area.$model"
                  :error="$v.form.area.$invalid && submitUpload"
                  clearable
                  :items="departments"
                  item-text="name"
                  item-value="id"
                  label="Area"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-autocomplete
                  v-model="$v.form.activity.$model"
                  :error="$v.form.activity.$invalid && submitUpload"
                  clearable
                  :items="activities"
                  :disabled="!$v.form.area.$model"
                  item-text="name"
                  item-value="id"
                  label="Actividad"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-autocomplete
                  v-model="$v.form.revision_id.$model"
                  :error="$v.form.revision_id.$invalid && submitUpload"
                  clearable
                  :items="revisions"
                  :disabled="!$v.form.activity.$model"
                  item-text="name"
                  item-value="id"
                  label="Revisión"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-text-field
                  v-model="$v.form.hours.$model"
                  :error="$v.form.hours.$invalid && submitUpload"
                  label="HH"
                  type="number"
                  clearable
                  :min="1"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-textarea
                  v-model="$v.form.comment.$model"
                  label="Comentario"
                  rows="3"
                  clearable
                ></v-textarea>
              </v-col>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="loading"
            color="red darken-1"
            outlined
            text
            @click="closeModalAddTracking"
          >
            <small>
              Cancelar
            </small>
          </v-btn>
          <v-btn
            color="blue darken-3"
            @click="handleSubmit"
            :disabled="loading"
            :loading="loading"
          >
            <small class="text-white">
              Añadir Tracking
            </small>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import { authUsuarioComputed } from '@/state/helpers'
import swal2 from 'sweetalert2'
export default {
  props: {
    modalAction: {
      type: Boolean,
      default: false
    },
    closeModalAddTracking: {
      type: Function,
      required: true,
      default: () => {}
    },
    userToTrack: {
      default: null
    },
    dateSelected: {
      default: null
    }
  },
  validations: {
    form: {
      project_code: { required },
      area: { required },
      activity: { required },
      revision_id: { required },
      hours: { required },
      comment: {}
    }
  },
  data() {
    return {
      loading: false,
      submitUpload: false,
      form: {
        project_code: null,
        area: null,
        activity: '',
        revision_id: null,
        hours: null,
        comment: null
      }
    }
  },
  mounted() {
    this.getAllProjects()
    this.getDepartments()
  },
  methods: {
    ...mapActions({
      getDepartments: 'tracking/getDepartments',
      getActivities: 'tracking/getActivities',
      getRevisions: 'tracking/getRevisions',
      getAllProjects: 'project/getAllProjects',
      createTracking: 'tracking/createTracking'
    }),
    resetForm() {
      this.form = {
        project_code: null,
        area: null,
        activity: '',
        revision_id: null,
        hours: null,
        comment: ''
      }
      this.submitUpload = false
    },
    async handleSubmit() {
      this.submitUpload = true
      if (!this.$v.form.$invalid) {
        this.loading = true
        const resp = await this.createTracking({
          hours: this.form.hours,
          date: this.dateSelected,
          created_by: this.user.email,
          user_id: this.userToTrack,
          revision_id: this.form.revision_id,
          project_code: this.form.project_code,
          comment: this.form.comment
        })
        if (resp.status === 200) {
          swal2.fire({
            icon: 'success',
            title: 'Excelente',
            text: 'Tracking agregado correctamente',
            position: 'top-end',
            showConfirmButton: false,
            toast: true,
            timer: 5000,
            timerProgressBar: true
          })
          this.$emit('trackingAdded')
          this.closeModalAddTracking()
          this.resetForm()
        }
      }
      this.loading = false
    }
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      projects: 'project/projects',
      departments: 'tracking/departments',
      activities: 'tracking/activities',
      revisions: 'tracking/revisions'
    })
  },
  watch: {
    'form.area': function(newArea) {
      this.getActivities({ department_id: newArea })
      this.form.activity = null
    },
    'form.activity': function(newActivity) {
      this.getRevisions({ activities_id: newActivity })
      this.form.revision_id = null
    }
  }
}
</script>

<style></style>
