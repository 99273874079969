<template>
  <v-container class="p-4">
    <section class="d-flex flex-column flex-sm-row justify-content-between">
      <h2 class="ml-1 my-3">
        Time Sheet Flux
      </h2>
      <v-breadcrumbs :items="breadcrumb">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item active-class="active" :to="item.to" :disabled="item.disabled">
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </section>
    <v-card class="p-3 mb-5 pb-5 pt-5">
      <v-row>
        <v-col cols="12" md="12" sm="12">
          <v-menu
            v-model="dateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="form.dateFormatted"
                class="w-50"
                label="Fecha"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="form.dateTracking"
              @input="dateMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="12" sm="12">
          <v-autocomplete
            v-model="form.user_id"
            :items="users"
            label="Usuario"
            class="w-50"
            item-text="name"
            item-value="id"
            prepend-icon="mdi-account"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="6" sm="12">
          <v-col cols="12" md="12" sm="12">
            <v-card-subtitle class="pb-0">
              Correo electrónico:
            </v-card-subtitle>
            <v-card-text class="" v-if="userSelected">
              <div>{{ userSelected.email }}</div>
            </v-card-text>
          </v-col>
          <v-col cols="12" md="12" sm="12">
            <v-card-subtitle class="pb-0">
              RUT:
            </v-card-subtitle>
            <v-card-text class="" v-if="userSelected">
              <div>{{ userSelected.rut ? userSelected.rut : 'Sin RUT' }}</div>
            </v-card-text>
          </v-col>
          <v-col cols="12" md="12" sm="12">
            <v-card-subtitle class="pb-0">
              Total HH:
            </v-card-subtitle>
            <v-card-text class="" v-if="userSelected">
              <div>{{ countTotalHH }}</div>
            </v-card-text>
          </v-col>
        </v-col>
        <v-col cols="12" md="6" sm="12">
          <v-col cols="12" md="12" sm="12">
            <v-card-subtitle class="pb-0">
              Área de Trabajo:
            </v-card-subtitle>
            <v-card-text class="" v-if="userActiveContract">
              <div>
                {{
                  userActiveContract.org_unit
                    ? userActiveContract.org_unit
                    : 'Sin área de trabajo '
                }}
              </div>
            </v-card-text>
          </v-col>
          <v-col cols="12" md="12" sm="12">
            <v-card-subtitle class="pb-0">
              Cargo:
            </v-card-subtitle>
            <v-card-text class="" v-if="userActiveContract">
              <div>
                {{ userActiveContract.position ? userActiveContract.position : 'Sin cargo ' }}
              </div>
            </v-card-text>
          </v-col>
          <v-col cols="12" md="12" sm="12">
            <v-card-subtitle class="pb-0">
              Fecha del contrato:
            </v-card-subtitle>
            <v-card-text class="mt-2" v-if="userActiveContract">
              <v-row>
                <v-col cols="12" md="6" sm="12" class="p-0">
                  <v-card-subtitle class="pb-0 pl-3">
                    Desde:
                  </v-card-subtitle>
                  <v-card-text class=" pl-3">
                    <div>{{ formatDateToHumanMethod(userActiveContract.since) }}</div>
                  </v-card-text>
                </v-col>

                <v-col cols="12" md="6" sm="12" class="p-0">
                  <v-card-subtitle class="pb-0">
                    Hasta:
                  </v-card-subtitle>
                  <v-card-text class="">
                    <div>
                      {{
                        userActiveContract.until
                          ? formatDateToHumanMethod(userActiveContract.until)
                          : 'Indefinido '
                      }}
                    </div>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-card-text>
          </v-col>
        </v-col>
      </v-row>
    </v-card>
    <v-card>
      <v-data-table
        class="elevation-3 px-3 mt-1"
        :headers="tableHeaders"
        :items="wrappedTrackingItems"
        :items-per-page="20"
        item-key="id"
        hide-default-footer
        no-data-text="Sin HH registradas actualmente"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-3"
              v-if="rolModuloPermiso.escritura"
              @click="openModalAddTracking"
              :dark="false"
              :disabled="!form.user_id || !form.dateTracking"
              size="small"
            >
              <span class="text-white">
                Agregar Lineas
              </span>
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <div class="d-flex align-items-center justify-content-center">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  :color="item.comment ? 'primary' : 'secondary'"
                  @click="showCurrentTrackingComment(item)"
                  :disabled="!item.comment"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon small>
                    mdi-alert-circle
                  </v-icon>
                </v-btn>
              </template>
              <span>Comentarios</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="rolModuloPermiso.escritura"
                  @click="deleteItem(item)"
                  icon
                  color="red darken-3"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon small>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>Eliminar</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </v-card>
    <!-- Añadir nuevo tracking -->
    <add-tracking
      :modalAction="dialogAddTracking"
      :closeModalAddTracking="closeModalAddTracking"
      :userToTrack="form.user_id"
      :dateSelected="form.dateTracking"
      @trackingAdded="getTrackingData"
    ></add-tracking>

    <!-- Comentario del tracking -->
    <tracking-comment
      :currentTrackingComment="currentTrackingComment"
      :dialogShowTrackingComment="dialogShowTrackingComment"
      :closeModalShowTrackingComment="closeModalShowTrackingComment"
    ></tracking-comment>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { preventCloseIfNotKeyEventEsc } from '@/helpers/common'
import AddTracking from './components/AddTracking.vue'
import { formatDateToHuman } from '@/helpers/common'
import { authMethods, authUsuarioComputed } from '@/state/helpers'
import { permiso } from '@/helpers/authservice/obtener-permisos'
import TrackingComment from './components/TrackingComment.vue'
export default {
  components: { AddTracking, TrackingComment },
  mounted() {
    this.cleanTrackings()
    this.getAllUsersActiveContract({
      date: this.form.dateTracking
    })
    let permisos = permiso.obtenerPermisos(this.user, this.$router.currentRoute.name)
    this.rolModuloPermiso = permisos
  },
  data() {
    return {
      dialogAddTracking: false,
      dialogShowTrackingComment: false,
      dateMenu: false,
      filters: {
        page: 1
      },
      form: {
        user_id: null,
        dateTracking: new Date().toISOString().split('T')[0],
        dateFormatted: formatDateToHuman(new Date().toISOString().split('T')[0])
      },
      userSelected: null,
      userActiveContract: null,
      pageCount: 0,
      currentTrackingComment: null,
      breadcrumb: [
        {
          text: 'Proyectos',
          to: { name: 'proyectos' }
        },
        {
          text: 'Time Sheet Flux',
          disabled: true
        }
      ],
      tableHeaders: [
        {
          text: 'Centro Costo',
          align: 'center',
          sortable: true,
          value: 'project.code'
        },
        {
          text: 'Area',
          align: 'center',
          sortable: true,
          value: 'revision.activity.department.name'
        },
        {
          text: 'Actividad',
          align: 'center',
          sortable: true,
          value: 'revision.activity.name'
        },
        {
          text: 'Revisión',
          align: 'center',
          sortable: true,
          value: 'revision.name'
        },
        {
          text: 'HH',
          align: 'center',
          sortable: true,
          value: 'hours'
        },
        {
          text: 'Acciones',
          align: 'center',
          value: 'actions'
        }
      ],
      //Permisos
      rolModuloPermiso: {}
    }
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      users: 'user/users',
      trackings: 'tracking/trackings'
    }),
    countTotalHH() {
      if (this.wrappedTrackingItems.length) {
        return this.wrappedTrackingItems.reduce(
          (accumulator, currentValue) => accumulator + currentValue.hours,
          0
        )
      }
      return 0
    },
    wrappedTrackingItems() {
      return this.trackings
    }
  },
  methods: {
    ...authMethods,
    ...mapActions({
      getAllUsersActiveContract: 'user/getAllUsersActiveContract',
      getUserActiveContract: 'user/getUserActiveContract',
      getUserFromEmail: 'user/getUserFromEmail',
      getTrackings: 'tracking/getTrackings',
      cleanTrackings: 'tracking/cleanTrackings',
      deleteTracking: 'tracking/deleteTracking'
    }),
    async getTrackingData() {
      await this.getTrackings({
        user_id: this.form.user_id,
        date: this.form.dateTracking
      })
    },
    async getUserActiveContractData() {
      this.userActiveContract = await this.getUserActiveContract({
        user_id: this.form.user_id,
        date: this.form.dateTracking
      })
    },
    openModalAddTracking() {
      this.dialogAddTracking = true
    },
    closeModalAddTracking($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event)
      if (validationEvent) {
        this.dialogAddTracking = false
      }
    },
    async setUserSelected(userId) {
      this.userSelected = this.users.find((user) => user.id === userId)
      if (this.userSelected && this.userSelected.email) {
        const data = await this.getUserFromEmail({ email: this.userSelected.email })
        this.userSelected.photo_url = data.photo_url
      }
    },
    checkIfCurrentUserIsManteined() {
      return this.users.find((user) => user.id === this.form.user_id)
    },
    showCurrentTrackingComment(item) {
      ;(this.currentTrackingComment = item.comment), (this.dialogShowTrackingComment = true)
    },
    closeModalShowTrackingComment($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event)
      if (validationEvent) {
        this.dialogShowTrackingComment = false
      }
    },
    deleteItem({ id }) {
      let self = this
      this.$swal
        .fire({
          icon: 'error',
          title: `Deseas eliminar este tracking ?`,
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: 'Eliminar',
          cancelButtonText: `Cancelar`
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            const resp = await this.deleteTracking({
              tracking_id: id,
              user: self.user.email
            })
            if (resp.status === 200) {
              self.$swal.fire({
                icon: 'success',
                title: 'Excelente',
                text: 'Tracking eliminado correctamente',
                position: 'top-end',
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true
              })
              self.getTrackingData()
            }
          }
        })
    },
    formatDateToHumanMethod(date) {
      return formatDateToHuman(date)
    }
  },
  watch: {
    'form.user_id': function(newUserId) {
      this.setUserSelected(newUserId)
      if (this.form.user_id && this.form.dateTracking) {
        this.getTrackingData()
        this.getUserActiveContractData()
      }
    },
    'form.dateTracking': async function(date) {
      this.form.dateFormatted = formatDateToHuman(date)
      await this.getAllUsersActiveContract({
        date: this.form.dateTracking
      })
      if (this.form.user_id && this.form.dateTracking) {
        this.getTrackingData()
        await this.getUserActiveContractData()
      }
      if (!this.checkIfCurrentUserIsManteined()) {
        this.form_user_id = null
        this.userSelected = null
        this.userActiveContract = null
      }
    }
  }
}
</script>

<style></style>
